import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class cannedResourcesService {

  private url = Constants.API_URL;
  constructor(private http: HttpClient) { }

  getUser(data) {
    return this.http.post(this.url + "getUser", data).map(res => res['result']);
  }
  // get
  getCannedData(data) {
    return this.http.post(this.url + "get_canned", data);
  }
  // add
  addCannedData(data) {
    return this.http.post(this.url + "add_canned", data);
  }
  // update
  updateCannedData(data: any, id: any) {
    return this.http.post(`${this.url}update_canned/${id}`, data);
  }
  // delete
  deleteCannedData(id: any) {
    return this.http.post(`${this.url}delete_canned/${id}`, null);
  }

}
