import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../shared/shared.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-recurring_notification',
  templateUrl: './recurring_notification.component.html',
  styleUrls: ['./recurring_notification.component.css']
})

export class Recurring_notificationComponent implements OnInit {
  notification: any;
  modalRef: BsModalRef;
  // forms = <any>[];
  forms = <any>{};
  editUser: any;
  flag: boolean = false;
  params: any;
  showTable: boolean = false;
  returnedArray: any[];
  currentPage = 1;
  length: any;
  search: any;
  current_date: any;

  sortOrder: any = 'ASC';
  bsValue = new Date();
  maxDate = new Date();
  ismeridian: boolean = true;
  pages = [ 
    {'key':'home', 'title':'Home'},
    {'key':'resources', 'title':'Resources'},
    {'key':'prayer', 'title':'Prayer'},
    {'key':'connect', 'title':'Connect'},
    {'key':'profile', 'title':'Profile'},
    {'key':'podcast', 'title':'Podcast'},
    {'key':'feedback', 'title':'Feedback'},
    {'key':'help', 'title':'Help'},
    {'key':'contactus', 'title':'Contact us'},
  ];

  constructor(private modalService: BsModalService,
    private _service: SharedService,
    private toastr: ToastrService) { this.maxDate.setDate(this.maxDate.getDate() + 7); }

  ngOnInit() {
    this.search = '';
    this.forms.day = 0;
    this.params = 1;
    this.listAll();
    this.forms.date = this.maxDate;
  }

  toggleMode(): void {
    this.ismeridian = !this.ismeridian;
  }

  listAll() {
    if (this.search == '') {
      this._service.listAllRecurring(this.params).subscribe(response => {
        if (response.status == true) {
          this.notification = response.info;
          if (this.notification.length > 0) {
            this.showTable = true;
            this.length = this.notification.length;
            this.paginationList(this.notification);
          } else {
            this.showTable = false;
          }
        }
      })
    } else {
      this._service.listAllRecurring(this.params, this.search).subscribe(response => {
        if (response.status == true) {
          this.notification = response.info;
          if (this.notification.length > 0) {
            this.showTable = true;
            this.length = this.notification.length;
            this.paginationList(this.notification);
          } else {
            this.showTable = false;
          }
        }
      })
    }

  }

  sortTable(n, sortColumn = '') {
    let table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("myTable");
    switching = true;

    this.sortOrder = this.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    // dir = "asc";
    let tempArr = this.notification.sort((a, b) => {
      console.log(a);
      console.log(b);
      var data1;
      var data2;
      switch (sortColumn) {

        default:
          if (a) {
            data1 = a[sortColumn].toUpperCase(); // ignore upper and lowercase
          }
          if (b) {
            data2 = b[sortColumn].toUpperCase(); // ignore upper and lowercase
          }
          break;
      }

      if (data1 < data2) {
        return this.sortOrder == 'ASC' ? -1 : 1;
      }
      if (data1 > data2) {
        return this.sortOrder == 'ASC' ? 1 : -1;
      }
      // names must be equal
      return 0;
    })
    this.paginationList(tempArr);
  }

  paginationList(tagslist: any) {
    if (this.currentPage != 1) {
      const startItem = (this.currentPage - 1) * 15;
      const endItem = this.currentPage * 15;
      this.returnedArray = tagslist.slice(startItem, endItem);
    }
    else {
      this.returnedArray = tagslist.slice(0, 15);
    }
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.notification.slice(startItem, endItem);
    this.currentPage = event.page;
  }

  open(template: TemplateRef<any>) {
    let today = new Date();

    console.log(today);
    let formatted_date = (today.getMonth() + 1) + "/" + (today.getDate()) + "/" + today.getFullYear();
    this.forms = {};
    this.forms.date = formatted_date;
    this.current_date = formatted_date;
    this.modalRef = this.modalService.show(template, {});
  }
  sendNotification(forms) {
    if (forms.id) {
      this._service.func(forms).subscribe(response => {
        if (response.status == true) {
          this.toastr.success(response.message, 'Success');
          this.forms = {};
          this.modalRef.hide();
          this.listAll();
        } else {
          this.toastr.error(response.message, 'error');
        }
      })
    } else {
      if (this.current_date == this.forms.date) {
        this.forms.current_date = true;
      } else {
        this.forms.current_date = false;
      }
      this._service.func(this.forms).subscribe(response => {
        if (response.status == true) {
          this.toastr.success(response.message, 'Success');
          this.forms = {};
          this.modalRef.hide();
          this.listAll();
        } else {
          this.toastr.error(response.message, 'error');
        }
      })
    }
  }
  edit(data, template) {
    this.forms = data;
    this.forms.not_time = this.forms.n_date + this.forms.n_time;
    this.modalRef = this.modalService.show(template, {});
  }

  deleteNotification(id) {
    if (confirm('Are you sure want to delete?')) {
      this.forms.id = id;
      this._service.delete(this.forms).subscribe(response => {
        if (response.status == true) {
          this.toastr.success(response.message, 'Success');
          this.listAll();
        } else {
          this.toastr.error(response.message, 'error');
        }
      })
    } else {
      return false;
    }
  }

}