import { Component, OnInit,ViewEncapsulation  } from '@angular/core';
import * as Rx from "rxjs";
import { Constants } from '../constants';
declare const $: any;
const getFooterTop = new Rx.BehaviorSubject(0);

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class FooterComponent implements OnInit {
  footerTop = 0;
  APP_VERSION: any = Constants.APP_VERSION;
  // fauth: any;
  constructor() { 
    getFooterTop.subscribe((data) =>{
      // console.log(data);
      this.footerTop = data;
    })
  }

  ngOnInit() {
    $(window).resize(function(){
      var chk_account_height = $("#top-header").outerHeight(true);

      // this.fauth = localStorage.getItem('auth');
      // if(this.auth){
        
      // }
      // else{
       
      // }
      // console.log(this.fauth);

      // if(chk_account_height == undefined){
      //   chk_account_height = 70;
      // }
      var window_height = $(window).height();
      var newFooterTop = window_height - chk_account_height;
      // console.log(window_height,chk_account_height,newFooterTop)
      getFooterTop.next(newFooterTop);
      // $("#footer").css('top', window_height - chk_account_height);  
    }).resize();
  }
}
