import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChatServiceService } from 'src/app/chat/chat-service.service';
import { MessagingService } from 'src/app/service/messaging.service';
import { logger } from 'src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/utils/common';
import * as  enums from 'src/utils/enums';
import * as  types from 'src/utils/types';


@Component({
  selector: 'app-groupchat',
  templateUrl: './groupchat.component.html',
  styleUrls: ['./groupchat.component.css']
})
export class GroupchatComponent implements OnInit, OnDestroy {

  IntervalUsersList: any = null;
  userChatList: any = null;
  currentChatUser: any = null;
  newChatReq: any = () => { };

  chatUsers: types.chatUsers = {
    listTitle: 'New Users',
    userList: [],
    userMessageList: [],
    userMessageListTem: [],
    currentUser: null,
    isChatEnd: false,
    isOnline: true
  }
  fireStoreUsers: any = null;
  searchUserVlu: any = null;
  endChatBySupport: boolean = false;
  constructor(private MessagingService: MessagingService,
    private chatService: ChatServiceService
  ) {
    MessagingService.getAllDataFromCollection('users').subscribe(data => {
      if (data) {
        this.fireStoreUsers = data;
      } else {
        this.fireStoreUsers = [];
      }
    });
    MessagingService.getDocumentById('users', '1').subscribe(data => {
      if (data) {
        this.chatUsers = {
          ...this.chatUsers,
          isOnline: data.is_online
        }
      } else {
        this.chatUsers = {
          ...this.chatUsers,
          isOnline: false
        }
      }
    });
  }

  ngOnInit(): void {
    try {
      this.MessagingService.addDataByCollectionRef(`/users/1`, {
        user_id: "1",
        first_name: "CR Admin",
        email: 'admin@crossroads.net',
        fcm_token: "adminsdewqrewrfdsf",
        groups: [],
        is_online: false,
        last_name: "Admin",
        platform: "Web",
        role: "support",
        last_active: null,
        profile_url: null,
        created_at: this.formatDateToCustomFormat(new Date(Date.now())),
        updated_at: this.formatDateToCustomFormat(new Date(Date.now())),
      })
    } catch (error) {
      console.log("🚀 ~ file: groupchat.component.ts:71 ~ GroupchatComponent ~ ngOnInit ~ error:", error)
      logger(error);
    }
  }

  ngOnDestroy() {
    this.handleChangeOnlineStatus(false);
  }

  getUser() {
    const formData: FormData = new FormData();
    formData.append('support_uid', "1");
    formData.append('is_login', "true");

    this.chatService.getChatUsersList(formData).subscribe((res: any) => {
      if (res?.status && res.data.length) {
        if (this.searchUserVlu) {
          this.chatUsers = {
            ...this.chatUsers,
            userList: res.data.filter(ele => ele.customer_name.includes(this.searchUserVlu))
          }
        } else {
          this.chatUsers = {
            ...this.chatUsers,
            userList: res.data
          }
        }
      } else {
        this.chatUsers = {
          ...this.chatUsers,
          userList: []
        }
      }
    });
  }

  actionHandler(action: any) {
    try {
      let message = action.payLoad;

      switch (action.type) {
        case enums.START_CHAT: {
          this.startUserChat(message);
          break;
        }

        case enums.SEND_SMART_REPLY: {
          this.sendTextMessage(message);
          break;
        }
        case enums.CHAT_END: {
          this.endChat(message);
          break;
        }
        case enums.SEARCH_USER: {
          this.searchUser(message);
          break;
        }
        case enums.IS_ONLINE: {
          this.handleChangeOnlineStatus(message);
          break;
        }
        // case enums.CLEAR_MESSAGE_TO_BE_UPDATED: {
        //   this.messageToBeEdited = null;
        //   break;
        // }
        // case enums.EDIT_MESSAGE: {
        //   this.editMessage(message);
        //   break;
        // }
        // case enums.MESSAGE_EDIT: {
        //   this.usermsg = null;
        //   this.getConversation()
        //   break;
        // }
        // case enums.DELETE_MESSAGE: {
        //   this.deleteConversation(message.id)
        //   break;
        // }
      }
    } catch (error) {
      console.error('Error adding document: ', error);
      logger(error);
    }
  }

  searchUser(vlu) {
    if (vlu) {
      this.searchUserVlu = vlu;
      if (this.searchUserVlu) {
        this.chatUsers = {
          ...this.chatUsers,
          userList: this.chatUsers.userList.filter(ele => ele.customer_name.includes(this.searchUserVlu))
        }
      }
    } else this.searchUserVlu = null;
  }

  handleChangeOnlineStatus(vlu) {
    this.MessagingService.addDataByCollectionRef(`/users/1`, {
      user_id: "1",
      first_name: "CR Admin",
      email: 'admin@crossroads.net',
      fcm_token: "adminsdewqrewrfdsf",
      groups: [],
      is_online: vlu,
      last_name: "Admin",
      platform: "Web",
      role: "support",
      last_active: null,
      profile_url: null,
      created_at: this.formatDateToCustomFormat(new Date(Date.now())),
      updated_at: this.formatDateToCustomFormat(new Date(Date.now())),
    })
    if (vlu) {
      this.getUser();
      this.IntervalUsersList = setInterval(() => {
        this.getUser();
      }, 5000);
    } else {
      if (this.IntervalUsersList) {
        this.chatUsers = {
          ...this.chatUsers,
          userList: [],
          userMessageList: [],
          userMessageListTem: [],
          currentUser: null,
          isChatEnd: false,
        }
        clearInterval(this.IntervalUsersList)
      }
    }

  }

  startUserChat(user) {
    if (user) {
      if (this.fireStoreUsers) {
        const isNew = this.fireStoreUsers.some(ele => ele.user_id === user.customer_uid);
        if (!isNew) {
          this.addUserToFiresore(user);
        } else {
          this.chatUsers = {
            ...this.chatUsers,
            currentUser: user,
            userMessageListTem: [],
            userMessageList: []
          }
          this.newChatRequest(user)
          this.endChatBySupport = false;
        }
      } else {
        this.addUserToFiresore(user);
      }
    }
  }

  newChatRequest(user) {
    if (user.support_uid === null) {
      const formData: FormData = new FormData();
      formData.append('group_uid', user.group_uid);
      formData.append('customer_uid', user.customer_uid);
      formData.append('customer_name', user.customer_name);
      formData.append('support_name', 'CR Admin');
      formData.append('support_username', 'admin@crossroads.net');
      formData.append('support_uid', '1');
      formData.append('customer_username', user.customer_username);
      formData.append('request_from', user.request_from);
      this.chatService.setSupportBrowser(formData).subscribe((res: any) => {
        if (res.status) {
          this.chatUsers = {
            ...this.chatUsers,
            currentUser: user
          }
          this.loginUser(1);
          this.getUser();
        }
      });
    } else {
      this.chatUsers = {
        ...this.chatUsers,
        currentUser: user
      }
      this.getUserMessages();
      this.getUser();
    }
  }

  loginUser(newRquest) {
    if (newRquest == 1) {
      var support_name = 'CR Admin';
      let messageText = 'Welcome to Crossroads, my name is ' + support_name + ', how can I help you in your career?';
      this.sendTextMessage(messageText, 'first_1');
    }
  }

  endChat(user) {
    this.chatUsers = {
      ...this.chatUsers,
      currentUser: null
    }
    this.endChatBySupport = true;
    this.MessagingService.deleteCollection('groups/' + user.group_uid + '/chats')
      .then(() => {
        this.MessagingService.deleteDocument('groups', user.group_uid)
          .catch(error => {
            console.error("Error deleting document:", error);
          });
      })
      .catch(error => {
        console.error("Error deleting document:", error);
      });
  }

  addUserToFiresore(user) {
    if (user) {
      this.MessagingService.addDataByCollectionRef(`/users/${user.customer_uid}`, {
        user_id: user.customer_uid,
        first_name: user.customer_name,
        email: null,
        fcm_token: null,
        groups: [],
        is_online: false,
        last_name: null,
        last_active: null,
        profile_url: null,
        created_at: this.formatDateToCustomFormat(new Date(Date.now())),
        updated_at: this.formatDateToCustomFormat(new Date(Date.now())),
      }).then(res => {
        this.newChatRequest(user)
      })
    }
  }

  sendTextMessage(msg, msgID = null) {
    if (msg) {
      const message = {
        message_body: {
          text: msg
        },
        sender: {
          user_id: "1",
          first_name: "CR Admin",
          email: 'admin@crossroads.net',
          fcm_token: "adminsdewqrewrfdsf",
          groups: [],
          is_online: false,
          last_name: "Admin",
          last_active: null,
          profile_url: null,
          created_at: this.formatDateToCustomFormat(new Date(Date.now())),
          updated_at: this.formatDateToCustomFormat(new Date(Date.now())),
        },
        message_id: msgID ? msgID : this.generateRendomID(),
        sender_id: "1",
        message_status: 'active',
        message_type: 'text',
        seen: false,
        seen_at: null,
        who_can_seen: null,
        delivered: true,
        sent_at: this.formatDateToCustomFormat(new Date(Date.now())),
        updated_at: this.formatDateToCustomFormat(new Date(Date.now())),
        delivered_at: this.formatDateToCustomFormat(new Date(Date.now())),
        created_at: this.formatDateToCustomFormat(new Date(Date.now())),
      }
      this.MessagingService.addDataByCollectionRef(`/groups/${this.chatUsers.currentUser.customer_uid}_guid/chats/${message.message_id}`, message)
        .then((docRef) => {
          this.getUserMessages();
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    }
  }

  getUserMessages() {
    this.MessagingService.getAllDataFromCollection(`/groups/${this.chatUsers.currentUser.customer_uid}_guid/chats`).subscribe(msg => {
      if (msg && msg.length) {
        this.chatUsers = {
          ...this.chatUsers,
          userMessageListTem: msg,
          isChatEnd: false
        }
      } else {
        if (!this.endChatBySupport) {
          this.chatUsers = {
            ...this.chatUsers,
            userMessageListTem: [],
            isChatEnd: true
          }
        } else {
          this.chatUsers = {
            ...this.chatUsers,
            userMessageListTem: [],
            userMessageList: [],
            isChatEnd: false
          }
        }
      }
    })
  }

  generateRendomID() {
    // return Math.floor(100000 + Math.random() * 900000).toString();
    return Date.now().toString();
  }

  formatDateToCustomFormat(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }
}



