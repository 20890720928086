import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../dashboard/dashboard.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-announcement-history',
  templateUrl: './announcement-history.component.html',
  styleUrls: ['./announcement-history.component.css']
})
export class AnnouncementHistoryComponent implements OnInit {
  settings: any = {};
  history: any[];
  organizations: any;
  users: any;
  contentlibrary: any;
  collections: any;
  tags: any;
  search_script: any;
  resource_script: any;
  org_list: any[];
  partner_list: any[];
  userId: any;
  params: any;
  notifications: any = {};
  modalRef: BsModalRef;
  forms = <any>{};
  Aforms = <any>{};
  aanouncementForm: FormGroup;
  returnedArray: any[];
  currentPage = 1;
  length: any;
  sortOrder: any = 'ASC';
  bsConfig: Partial<BsDatepickerConfig>;
  file!: File;

  pages = [
    { 'key': 'home', 'title': 'Home' },
    { 'key': 'resources', 'title': 'Resources' },
    { 'key': 'prayer', 'title': 'Prayer' },
    { 'key': 'connect', 'title': 'Connect' },
    { 'key': 'profile', 'title': 'Profile' },
    { 'key': 'podcast', 'title': 'Podcast' },
    { 'key': 'feedback', 'title': 'Feedback' },
    { 'key': 'chat', 'title': 'Chat' },
    { 'key': 'help', 'title': 'Help' },
    { 'key': 'contactus', 'title': 'Contact us' },
  ];
  id: any; x

  constructor(
    private service: SharedService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    public Route: Router
  ) { }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { dateInputFormat: 'MM/DD/YYYY' });

    this.params = 1;
    this.listAll();
    this.Aforms.redirect_type = 'app';
  }
  showAnnouncement() {
    this.service.showAnnouncement().subscribe(response => {
      this.Aforms.title = response[0]?.title;
      this.Aforms.link = response[0]?.link;
      this.Aforms.date = response[0]?.date;
      this.Aforms.description = response[0]?.description;

    })
  }

  addAnnouncement(template2: any, param = '') {
    this.modalRef = this.modalService.show(template2, {});
    // this.showAnnouncement()
  }

  test(e: any) {
    if (e) {
      var fdate = this.datepipe.transform(e[0], 'MM/dd/yyyy');
      var edate = this.datepipe.transform(e[1], 'MM/dd/yyyy');
      this.Aforms.date = fdate + " - " + edate;
    }
  }

  onFileChange(event) {
    this.file = event.target.files[0];
  }

  onRedirectTypeChange(event) {
    if (event.target.value === 'app') {
      this.Aforms.link = '';
    } else {
      this.Aforms.page = '';
    }
  }

  sendAnnouncement(Aforms) {
    const formData: FormData = new FormData();
    formData.append('img', this.file);
    formData.append('title', Aforms?.title);
    formData.append('description', Aforms?.description);
    formData.append('link', (Aforms.redirect_type === 'web' && Aforms?.link) ? Aforms?.link : '');
    formData.append('page_redirect', (Aforms.redirect_type === 'app' && Aforms?.page) ? Aforms?.page : '');
    formData.append('url_type', Aforms.redirect_type);
    formData.append('date', Aforms?.date);

    this.service.updateAnnouncement(formData).subscribe(response => {
      if (response.status == true) {
        this.listAll();
        this.toastr.success('Success', response.message);
        this.resetAnnouncement(this.Aforms)
        this.modalRef.hide();
      } else {
        this.toastr.error('error', response.message);
      }
    })
  }

  resetAnnouncement(Aforms) {
    Aforms.title = null;
    Aforms.description = null;
    Aforms.page = null;
    Aforms.link = null;
    Aforms.date = null;
    Aforms.redirect_type = 'app';
  }

  decline() {
    this.modalRef.hide();
  }

  sortTable(n, sortColumn = '') {

    let table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("myTable");
    switching = true;

    this.sortOrder = this.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    // dir = "asc";
    let tempArr = this.notifications.sort((a, b) => {
      console.log(a);
      console.log(b);
      var data1;
      var data2;
      switch (sortColumn) {

        default:
          if (a) {
            data1 = a[sortColumn].toUpperCase(); // ignore upper and lowercase
          }
          if (b) {
            data2 = b[sortColumn].toUpperCase(); // ignore upper and lowercase
          }
          break;
      }

      if (data1 < data2) {
        return this.sortOrder == 'ASC' ? -1 : 1;
      }
      if (data1 > data2) {
        return this.sortOrder == 'ASC' ? 1 : -1;
      }
      // names must be equal
      return 0;
    })
    this.paginationList(tempArr);
  }

  listAll() {
    this.service.showAnnouncementHistory().subscribe(response => {
      this.history = response?.data;
      console.log(response);
      this.paginationList(this.history);
      this.length = this.history?.length;

    })
  }

  paginationList(tagslist: any) {
    if (this.currentPage != 1) {
      const startItem = (this.currentPage - 1) * 15;
      const endItem = this.currentPage * 15;
      this.returnedArray = this.history.slice(startItem, endItem);
    }
    else {
      this.returnedArray = tagslist?.slice(0, 15);
    }
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.history.slice(startItem, endItem);
    this.currentPage = event.page;
  }

  deleteAnnouncement(id: any) {
    console.log(id);
    this.service.deleteAnnouncementHistory(id).subscribe(response => {
      this.listAll();
      this.toastr.success(response.message, 'success');
    }, (err: any) => {
      this.toastr.success(err.message, 'success');
    })
  }

}
