import { Component, OnInit, Renderer2 } from '@angular/core';
import {NavigationStart, Router , ActivatedRoute} from '@angular/router';
import { Constants } from './constants';
import { Title }     from '@angular/platform-browser';
import { SharedService } from './shared/shared.service';

declare const $: any;
declare const jquery: any;
declare const screenfull: any;
declare const swal: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
    previousUrl: string;

    public loading = false;
    versionError: boolean;

    constructor(
                private renderer: Renderer2, 
                private router: Router, 
                private route: ActivatedRoute,
                private titleService: Title,
                private _service: SharedService
            ) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    if (this.previousUrl) {
                        this.renderer.removeClass(document.body, this.previousUrl);
                    }
                    const currentUrl = event.url.split('/');
                    const currentUrlSlug = currentUrl[currentUrl.length - 1];
                    if (currentUrlSlug) {
                        this.renderer.addClass(document.body, currentUrlSlug);
                    }
                    this.previousUrl = currentUrlSlug;
                }
            });

    }
    userId:any;

    ngOnInit() {
        /* code added by dev@adarsh start */
        // this._service.getApiversion().subscribe(response => {
        //     console.log(response);
        //     if(response.status == true){
        //             if(Constants.APP_VERSION != response.info){
        //                 this.sweetDialog('confirm');
        //             }
        //             else{
        //                return false;
        //             }
        //         }
         
        //     else{
        //         alert(response.message);
        //     }
        // })
        
        /* code added by dev@adarsh end */
        console.log(this.route.url);
        // alert("adarsh");
        this.titleService.setTitle(Constants.PAGE_TITLE);

       
        /* code added by dev@av start */
       
        $('.boxs-close').on('click', function(){
            var element = $(this);
            var cards = element.parents('.card');
            cards.addClass('closed').fadeOut();
        });

        $('.theme-light-dark .t-light').on('click', function() {
            $('body').removeClass('menu_dark');
        });
        
        $('.theme-light-dark .t-dark').on('click', function() {
            $('body').addClass('menu_dark');
        });
        
        $('.m_img_btn').on('click',function() {
            $('body').toggleClass('menu_img');
        });

        $('.sparkline').each(function() {
            var $this = $(this);
            $this.sparkline('html', $this.data());
        });

    }
    sweetDialog(type){
        switch (type) {
            case 'confirm':
                this.showConfirmMessage();
            break;
        
            default:
                break;
        }
    }

    showConfirmMessage() {
        swal({
            title: 'Updates Available',
            textcolor: '#24213c',
            text: 'For better experience we have upgraded the feature in Crossroadscareer. please reload the page!',
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#1473ad',
            confirmButtonText: 'Yes, reload it!',
            closeOnConfirm: false
        }, function () {
            window.location.reload();
            swal('Updated!', 'Your Crossroadscareer file has been updated.', 'success');
            
        });
    }
}
