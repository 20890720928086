import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, Injectable } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpInterceptorService } from './http-interceptor';
import { SharedModule } from './shared/shared.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DndModule } from 'ngx-drag-drop';
// import { AuthenticationModule } from './authentication/authentication.module';
import { TagInputModule } from 'ngx-chips';
// import { TagsInputModule } from 'ngx-tags-input/dist';
// import {InputTextModule, ButtonModule, ConfirmDialogModule, PasswordModule, DropdownModule, SpinnerModule} from 'primeng/primeng';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ClickOutsideModule } from 'ng-click-outside';
import { DashboardModule } from './dashboard/dashboard.module';
import { AuthenticationService } from './authentication/authentication.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
// import { RteComponent } from './rte/rte.component';
import { CometModule } from './comet/comet.module';
import { NotificationComponent } from './notification/notification.component';
import { RecurringNotificationLogsComponent } from './recurring-notification-logs/recurring-notification-logs.component';
import { AnnouncementHistoryComponent } from './announcement-history/announcement-history.component';
import { CannedResourcesComponent } from './canned-resources/canned-resources.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ChatModule } from './chat/chat.module';
// import { SelectDropDownModule } from 'ngx-select-dropdown';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common';
declare var $;

@NgModule({
  declarations: [
    AppComponent,
    NotificationComponent,
    RecurringNotificationLogsComponent,
    AnnouncementHistoryComponent,
    CannedResourcesComponent
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    TagInputModule,
    CKEditorModule,
    // ConfirmDialogModule,
    // DropdownModule,
    // InputTextModule,
    // SpinnerModule,
    // ButtonModule,
    // PasswordModule,
    BrowserModule.withServerTransition({
      appId: 'CR'
    }),
    NoopAnimationsModule,
    DashboardModule,
    FormsModule,
    AppRoutingModule,
    HttpModule,
    NgxSpinnerModule,
    HttpClientModule,
    SlickCarouselModule,
    SharedModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    DndModule,

    // CKEditorModule,

    // AuthenticationModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ClickOutsideModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ChatModule,
    // SelectDropDownModule
    // TagsInputModule.forRoot()
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
  ],
  providers: [
    // HttpInterceptorService,
    AuthenticationService,
    HttpInterceptorService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }

  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
