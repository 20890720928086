import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from '../dashboard/dashboard-routing.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { RouterModule, Routes } from '@angular/router';
// const routes: Routes;
@NgModule({
  declarations: [DashboardComponent],
  imports: [
    PaginationModule.forRoot(),
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    // RouterModule.forRoot(Routes)
  ],
})

export class DashboardModule { }
