import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as types from 'src/utils/types'
import { ChatServiceService } from '../../chat-service.service';
import * as enums from 'src/utils/enums'
import { ElementRef } from '@angular/core';
import { logger } from 'src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/utils/common';
@Component({
  selector: 'app-user-list-with-messages',
  templateUrl: './user-list-with-messages.component.html',
  styleUrls: ['./user-list-with-messages.component.css']
})



export class UserListWithMessagesComponent implements OnInit, OnChanges {

  @Input() userData: types.chatUsers;
  @Output() actionGenerated: EventEmitter<any> = new EventEmitter();
  @ViewChild('template2') template2: any;
  @ViewChild('chatScreen') private myScrollContainer!: ElementRef;
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private chatService: ChatServiceService) { }

  ngOnInit(): void {
  }

  ngOnChanges(change: SimpleChanges) {
    try {
      if (change.userData.currentValue.userMessageListTem.length > change.userData.previousValue.userMessageListTem.length) {
        this.userData.userMessageList = [...change.userData.currentValue.userMessageListTem];
        setTimeout(() => this.scrollToBottom(), 300);
      }
      if (change.userData.currentValue.isChatEnd && change.userData.currentValue.isChatEnd != change.userData.previousValue.isChatEnd) {
        this.handleEndChat(this.template2);
      }
    } catch (error) {
      logger(error);
    }
  }

  handleEndChat(template: any) {
    this.modalRef && this.decline()
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  decline() {
    this.modalRef.hide();
  }

  endChatByUser() {
    this.userData.userMessageList = [];
    this.userData.currentUser = null;
    this.modalRef.hide();
  }

  endchat_click(user) {
    if (user) {
      const isEndChatByUser = this.userData.userList.find(ele => ele.customer_uid === this.userData.currentUser.customer_uid);
      if (isEndChatByUser) {
        this.chatService.endChat(user.customer_uid).subscribe((res: any) => {
          if (res.status) {
            this.modalRef.hide();
            this.actionHandler({
              type: enums.CHAT_END,
              payLoad: user
            })
          } else {
            this.modalRef.hide();
            this.endChatByUser();
          }
        });
      }
      else {
        this.modalRef.hide();
        this.endChatByUser()
      }
    } else {
      this.modalRef.hide();
      this.endChatByUser()
    }
  }

  actionHandler(action) {
    if (action.type === enums.SEND_SMART_REPLY) {
      const isEndChatByUser = this.userData.userList.find(ele => ele.customer_uid === this.userData.currentUser.customer_uid);
      if (isEndChatByUser) {
        this.actionGenerated.emit(action);
      } else {
        this.handleEndChat(this.template2)
      }
    } else {
      this.actionGenerated.emit(action);
    }
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }
}
