import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { cannedResourcesService } from './canned-resources.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-canned-resources',
  templateUrl: './canned-resources.component.html',
  styleUrls: ['./canned-resources.component.css']
})
export class CannedResourcesComponent implements OnInit {

  search: any;
  jwt: string;
  userList: any;
  length: any;
  returnedArray: any;
  sortOrder: any = 'ASC';
  currentPage = 1;
  type: any = 'admin';
  modalRef: BsModalRef;
  addForm!: FormGroup;
  editForm!: FormGroup;
  submitted = false;
  id: any;
  editId: number = null;


  constructor(private router: Router,
    private _service: cannedResourcesService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private toastr: ToastrService, private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required]),
      paragraph: new FormControl('', [Validators.required]),
    });
    this.editForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required]),
      paragraph: new FormControl('', [Validators.required]),
      is_active: new FormControl(1, [Validators.required]),
    });
    this.listAll(this.type);
  }

  add(template: TemplateRef<any>, id: any) {
    this.modalRef = this.modalService.show(template, {});
    this.id = id;
  }

  decline() {
    this.modalRef.hide();
    this.editForm.reset();
    this.submitted = false;
  }

  listAll(type: any) {
    this.type = type;
    const formData: FormData = new FormData();
    formData.append('type', type);
    this._service.getCannedData(formData).subscribe((response: any) => {
      if (response.status == true) {
        this.userList = response.data;
        this.length = this.userList.length;
        this.paginationList(this.userList);
      }
    })
  }

  paginationList(tagslist: any) {
    if (this.currentPage != 1) {
      const startItem = (this.currentPage - 1) * 15;
      const endItem = this.currentPage * 15;
      this.returnedArray = tagslist.slice(startItem, endItem);
      console.log(this.returnedArray);

    }
    else {
      this.returnedArray = tagslist.slice(0, 15);
    }
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.userList.slice(startItem, endItem);
    this.currentPage = event.page;
  }

  deleteUser(id) {
    if (confirm('Are you sure you want to delete this user?')) {
      this._service.deleteCannedData(id).subscribe((response: any) => {
        if (response.status == true) {
          this.toastr.success(response.message, 'Success');
          this.listAll(this.type);
        } else {
          this.toastr.error(response.message, 'error');
        }
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.addForm.invalid) {
      return;
    } else {
      const formData: FormData = new FormData();
      formData.append('title', this.addForm.value?.title);
      formData.append('paragraph', this.addForm.value?.paragraph);
      this._service.addCannedData(formData).subscribe((response: any) => {
        if (response.status) {
          this.listAll(this.type);
          this.addForm.reset();
          this.decline();
          this.toastr.success(response.message, 'Success');
        } else {
          this.toastr.error(response.message, 'error');
        }
      })
    }
  }

  edit(template: TemplateRef<any>, id: any, data: any) {
    this.editForm.get('title').setValue(data?.title);
    this.editForm.get('paragraph').setValue(data?.paragraph);
    this.editForm.get('is_active').setValue(data?.is_active);
    this.modalRef = this.modalService.show(template, {});
    this.id = id;
    this.editId = data.id;
  }

  handleSwitchChange(user: any) {
    const is_active: any = user.is_active ? 1 : 0;
    const formData: FormData = new FormData();
    formData.append('title', user?.title);
    formData.append('paragraph', user?.paragraph);
    formData.append('is_active', is_active);
    this._service.updateCannedData(formData, user?.id).subscribe((response: any) => {
      if (response.status) {
        this.listAll(this.type);
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'error');
      }
    })
  }

  handleIsActiveChange() {
    this.editForm.value.is_active = this.editForm.value.is_active ? 1 : 0;
  }

  onEditCanned() {
    this.submitted = true;
    if (this.editForm.invalid) {
      return;
    } else {
      const formData: FormData = new FormData();
      formData.append('title', this.editForm.value?.title);
      formData.append('paragraph', this.editForm.value?.paragraph);
      formData.append('is_active', this.editForm.value?.is_active);
      this._service.updateCannedData(formData, this.editId).subscribe((response: any) => {
        if (response.status) {
          this.listAll(this.type);
          this.decline();
          this.toastr.success(response.message, 'Success');
          this.editForm.reset();
        } else {
          this.toastr.error(response.message, 'error');
        }
      })
    }
  }

  sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
