import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { cannedResourcesService } from 'src/app/canned-resources/canned-resources.service';
import { logger } from 'src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/utils/common';
import { CHAT_CONSTANTS } from 'src/utils/messageConstants';
import * as  enums from 'src/utils/enums';

@Component({
  selector: 'app-chat-canned-resource',
  templateUrl: './chat-canned-resource.component.html',
  styleUrls: ['./chat-canned-resource.component.css']
})
export class ChatCannedResourceComponent implements OnInit {

  @Output() actionGenerated: EventEmitter<any> = new EventEmitter();

  decoratorMessage: string = CHAT_CONSTANTS.LOADING_MESSSAGE;
  loading: boolean = true;
  type: string = "all";
  cannedList: any = [];
  // Regular expression to find URLs
  urlRegex = /(https?:\/\/[^\s]+)/;

  constructor(
    private _service: cannedResourcesService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    try {
      this.getCannedList();
    } catch (error) {
      logger(error);
    }
  }

  /**
   * Fetches all the information of Canned Responses
   */

  getCannedList() {
    try {
      const formData: FormData = new FormData();
      formData.append('type', this.type);
      this._service.getCannedData(formData).subscribe((response: any) => {
        if (response.status == true) {
          if (response.data.length !== 0) {
            this.loading = false;
          }
          if (response.data.length === 0) {
            this.decoratorMessage = CHAT_CONSTANTS.NO_CANNED_FOUND;
          }
          this.cannedList = response.data;
        }
      })
    } catch (error) {
      logger(error);
    }
  }

  extractTextAndUrl(inputString: string) {
    const matchedUrls = inputString.match(this.urlRegex);
    if (matchedUrls) {
      const extractedUrl = matchedUrls[0];
      const extractedText = inputString.split(extractedUrl)[0];
      return this.sanitizer.bypassSecurityTrustHtml(`<p class="cannedParagraph">
                    ${extractedText}
                    <a href="${extractedUrl}" target="_blank">${extractedUrl}</a>
              </p>`)
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(`<p class="cannedParagraph">
            ${inputString}
      </p>`)
    }
  }

  /**
 * Sends  Canned Resource as Message
 * @param
 */
  sendStickerMessage(canned: any) {
    try {
      this.actionGenerated.emit({
        type: enums.SEND_CANNED_RESOURCE,
        payLoad: canned,
      });
    } catch (error) {
      logger(error);
    }
  }

  /**
   * Close Canned Resource Window
   * @param 
   */
  closeCannedResourceWindow(message: any) {
    try {
      this.actionGenerated.emit({
        type: message,
      });
    } catch (error) {
      logger(error);
    }
  }
}
