import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';

import 'rxjs/add/operator/map';

import * as Rx from "rxjs";
import { Constants } from '../constants';


@Injectable()
export class AuthenticationService {

    // private url = 'https://crossroadscareer.net/sandbox/public/';
    private url = Constants.API_URL;

    userInfo = new Rx.BehaviorSubject({});
    user: any = this.userInfo.asObservable();


    constructor(private https: HttpClient, private http: Http) {
        // let headers: HttpHeaders = new HttpHeaders();
        // headers = headers.append('Accept', 'application/json');
        // headers = headers.append('zumo-api-version', '2.0.0');
        // let headers = new Headers();
        // headers.append('Content-Type','application/json');
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        // let options = new RequestOptions({headers:headers});
        // console.log(headers);

    }

    // let headers = new Headers();
    //         headers.append('Content-Type','application/json');
    //         headers.append('Authorization',this.basic);
    //         let options = new RequestOptions({headers:headers});
    //         console.log(headers);

    isLoggedIn() {
        if (!localStorage.auth || localStorage.auth == '' || localStorage.auth == undefined) {
            return false;
        } else {
            return true;
        }
    }
    // login(data,options:any = this.options){
    //     return this.http.post(this.url+"login",data,options).map(res => JSON.parse(res['_body'])["result"]);
    // }
    login(data) {
        // return this.http.post(this.url+"login",data).map(res => JSON.parse(res['_body'])["result"]);
        // return this.https.post(this.url+"login",data).map(res => res["result"]);
        return this.http.post(this.url + "login", JSON.stringify(data)).map(res => JSON.parse(res['_body'])["result"]);
    }

    register(data) {
        return this.http.post(this.url + "callback/organizationselfregister", data).map(res => res["result"]);
    }

    logout(userId, admiId) {
        return this.http.get(this.url + "auth/logout/" + userId + "/" + admiId).map(res => res["result"]);
    }
    listAll(data) {
        return this.http.get(this.url + "users/list/all", data).map(res => res["result"]);
    }
    signupOrganization(data, files = null) {

        var formData: FormData = new FormData();
        var dataArr = Object.keys(data).map(function (key) {
            return [key, data[key]];
        });

        if (files != null && files.length > 0) {
            let index = 0;
            for (let file of files) {
                formData.append('image[' + index + ']', file, file.name);
                index++;
            }
        }
        dataArr.forEach((element) => {
            formData.append(element[0], element[1])
        });
        console.log(data)

        return this.http.post(this.url + "callback/organizationselfregister", data).map(res => JSON.parse(res['_body'])["result"]);
        // return this.http.post(this.url+"callback/contact",data).map(res => JSON.parse(res['_body'])["result"]);
    }

    updateUser(data) {
        return this.http.post(this.url + "/user/edit", data).map(res => res["result"]);
    }

    verifyToken(token) {
        return this.http.get(this.url + "/auth/verify/token/" + token).map(res => res["result"]);
    }

    resetPassword(data) {
        return this.http.post(this.url + "/callback/reset/password", data).map(res => JSON.parse(res['_body'])["result"]);
    }

    getHealthCareProvider() {
        return this.http.get(this.url + "/common/healthcare").map(res => res["result"]);
    }

    gethomecheck() {
        return this.http.get(this.url + '/').map(res => res["result"]);
    }
    userDetail() {
        return this.http.get(this.url + '/users/info').map(res => res["result"]);
    }

    getimage() {
        return this.http.get(this.url + 'users/all').map(res => res["result"]);
    }

    contact(data) {
        return this.http.post(this.url + "callback/contact", data).map(res => JSON.parse(res['_body'])["result"]);
    }

    Contactpage(data, files = null) {
        return this.http.post(this.url + "callback/contact", data).map(res => JSON.parse(res['_body'])["result"]);
    }

    addNewUser(forms) {
        return this.http.post(this.url + "callback/addviewer", forms).map(res => JSON.parse(res['_body'])["result"]);
    }

    userLinkdetail(u_type) {
        return this.http.get(this.url + "callback/getviewerdetail/" + u_type).map(res => JSON.parse(res['_body'])["result"]);
    }

    denominationlist(params, keyword: any = '') {

        if (keyword == '') {
            return this.http.get(this.url + "callback/denomination/all", params).map(res => JSON.parse(res['_body'])["result"]);
        } else {
            return this.http.get(this.url + "callback/denomination/all/" + keyword, params).map(res => res["result"]);
        }
    }

    forgetPasword(data) {
        return this.http.post(this.url + "forgetpassword", JSON.stringify(data)).map(res => JSON.parse(res['_body'])["result"]);
    }
}