import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardService } from './dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare const $: any;
declare const Morris: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  item: any;
  jwt: any;
  userCount: any;
  prayers: any = {};
  survey: number;
  userData: any = {};
  prayerData: any = {};
  surveyData: any = {};
  surveyGraph: any = {};
  questionOne: any = {};
  questionTwo: any = {};
  questionThree: any = {};
  questionFour: any = {};
  modalRef: BsModalRef;
  listcount: any;
  hidebtn: any;

  constructor(
    private router: Router,
    private _service: DashboardService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: BsModalService) {

  }
  userId: any;
  params: any;
  searching: any;
  statsType: any = {};

  ngOnInit() {
    var data = JSON.stringify(localStorage.getItem('user'));
    this.item = JSON.parse(data);
    console.log(this.item);
    let value = localStorage.getItem('auth');
    console.log(value);
    if ((!(value)) || value == null) {
      this.router.navigate(["authentication/signin"]);
    }
    // this.userId = this.item.id;
    // this.userList();
    this.statsData();

    $('body').removeClass('authentication sidebar-collapse');

    $('.ls-toggle-btn').on('click', function () {
      $('body').toggleClass('ls-toggle-menu');
    });
    this.params = 1;
    this.listcount = 1;
    this.hidebtn = false;
    // this.listAll();
  }
  @ViewChild('userListmodel') userListmodel: ElementRef;
  @ViewChild('prayer', { static: true }) prayer: ElementRef;
  @ViewChild('surveymodal', { static: true }) surveymodal: ElementRef;
  openModal(template: TemplateRef<any>, modalName, count) {
    // alert(modalName);
    switch (modalName) {
      case 'userListmodel':
        this._service.showList().subscribe(response => {
          console.log(response);
          if (response.status == true) {
            console.log(response);
            this.userData = response.data;
          } else {

          }
        })
        this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-lg' }));
        break;

      case 'prayer':
        this._service.showPrayers().subscribe(response => {
          console.log(response);
          if (response.status == true) {
            this.prayerData = response.data;
            console.log(this.prayers);
          } else {

          }
        })
        this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-lg' }));
        break;

      case 'surveymodal':
        // this.modalRef.hide()
        if (count == 'first') {
          this.hidebtn = false;
          this.listcount = 1;
          this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-xl' }));
        }

        var delta = this.listcount * 5;

        this._service.showSurvey(delta).subscribe(response => {
          if (response.status == true) {

            if (response.data.length < delta) {
              this.hidebtn = true;
            }

            this.surveyData = response.data;
            this.listcount = this.listcount + 1;
          } else {

          }
        })

        break;
    }
  }
  statsData() {
    this._service.statsData().subscribe(response => {
      if (response.status == true) {

        console.log(typeof this.survey);
        console.log(response);
        this.userCount = response.users;
        this.prayers = response.prayers;
        this.survey = response.survey;
        this.questionOne = response.questionOne;
        this.questionTwo = response.questionTwo;
        this.questionThree = response.questionThree;
        this.questionFour = response.questionFour;
        this.statsType.acquisition = 'traffic';
        let charttype1 = 'acquisition_donut_chart1';
        let charttype2 = 'acquisition_donut_chart2';
        let charttype3 = 'acquisition_donut_chart3';
        let charttype4 = 'acquisition_donut_chart4';
        if (this.questionOne.length > 0) {
          this.morrisBar1(charttype1, this.questionOne);
        }
        if (this.questionTwo.length > 0) {
          this.morrisBar2(charttype2, this.questionTwo);
        }
        if (this.questionThree.length > 0) {
          this.morrisBar3(charttype3, this.questionThree);
        }
        if (this.questionFour.length > 0) {
          this.morrisBar4(charttype4, this.questionFour);
        }
      } else {

      }
    })
  }

  morrisBar1(charttype, morrisChartArr) {
    $('#' + charttype).html('');

    var data = morrisChartArr;
    //       var data = [{
    //     label: 'abc.jpg',
    //     valu: 80,
    //     value: 56,
    //     percent: 52
    // }, {
    //     y: 'abc.jpg',
    //     Downloads: 75,
    //     Views: 65
    // }, {
    //     y: 'abc.jpg',
    //     Downloads: 59,
    //     Views: 30
    // }, {
    //     y: 'abc.jpg',
    //     Downloads: 75,
    //     Views: 65
    // }, {
    //     y: 'abc.jpg',
    //     Downloads: 55,
    //     Views: 40
    // }, {
    //     y: 'abc.jpg',
    //     Downloads: 75,
    //     Views: 65
    // }, {
    //     y: 'abc.jpg',
    //     Downloads: 87,
    //     Views: 88
    // }];
    var $arrColors = ['#1473ad', '#1b97e4', '#6fa3ce', '#636c72', '#b5b5b6',];
    if (data.length > 0) {
      Morris.Bar({
        element: charttype,
        data: data,
        xkey: ['label'],
        ykeys: ['value'],
        labels: ['value'],
        //barColors: ['#ffd97f'],
        barColors: function (row, series, type) {
          return $arrColors[row.x];
        },
        hideHover: 'always',
        gridLineColor: '#eef0f2',
        xLabelAngle: 45,
        resize: true
      });
      let ref = this;
      var items = $("#" + charttype).find("svg").find("rect");
      $.each(items, function (index, v) {
        var value = data[index].count;
        var newY = ($(this).attr('y') - 20);
        var halfWidth = ($(this).attr('width') / 2);
        var newX = parseFloat($(this).attr('x')) + halfWidth;
        var output = '<text style="text-anchor: middle;" x="' + newX + '" y="' + newY + '" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#000000" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.875)"><tspan dy="3.75">' + value + '</tspan></text>';
        $("#" + charttype).find("svg").append(ref.parseSVG(output));
      });
    }
  }

  parseSVG(s) {
    var div = document.createElementNS('https://www.w3.org/1999/xhtml', 'div');
    div.innerHTML = '<svg xmlns="https://www.w3.org/2000/svg">' + s + '</svg>';
    var frag = document.createDocumentFragment();
    while (div.firstChild.firstChild)
      frag.appendChild(div.firstChild.firstChild);
    return frag;
  }

  morrisBar4(charttype, morrisChartArr) {
    $('#' + charttype).html('');

    var data = morrisChartArr;
    var $arrColors = ['#1473ad', '#1b97e4', '#6fa3ce', '#636c72', '#b5b5b6',];
    if (data.length > 0) {
      Morris.Bar({
        element: charttype,
        data: data,
        xkey: 'label',
        ykeys: ['value'],
        labels: ['value'],
        //barColors: ['#fab2c0'],
        barColors: function (row, series, type) {
          return $arrColors[row.x];
        },
        hideHover: 'always',
        gridLineColor: '#eef0f2',
        xLabelAngle: 45,
        resize: true
      });
      let ref = this;
      var items = $("#" + charttype).find("svg").find("rect");
      $.each(items, function (index, v) {
        var value = data[index].count;
        var newY = ($(this).attr('y') - 20);
        var halfWidth = ($(this).attr('width') / 2);
        var newX = parseFloat($(this).attr('x')) + halfWidth;
        var output = '<text style="text-anchor: middle;" x="' + newX + '" y="' + newY + '" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#000000" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.875)"><tspan dy="3.75">' + value + '</tspan></text>';
        $("#" + charttype).find("svg").append(ref.parseSVG(output));
      });
    }

  }


  morrisBar3(charttype, morrisChartArr) {
    $('#' + charttype).html('');

    var data = morrisChartArr;
    var $arrColors = ['#1473ad', '#1b97e4', '#6fa3ce', '#636c72', '#b5b5b6',];
    if (data.length > 0) {
      Morris.Bar({
        element: charttype,
        data: data,
        xkey: 'label',
        ykeys: ['value'],
        labels: ['value'],
        //barColors: [ '#80dad8'],
        barColors: function (row, series, type) {
          return $arrColors[row.x];
        },
        hideHover: 'always',
        gridLineColor: '#eef0f2',
        xLabelAngle: 45,
        resize: true
      });
      let ref = this;
      var items = $("#" + charttype).find("svg").find("rect");
      $.each(items, function (index, v) {
        var value = data[index].count;
        var newY = ($(this).attr('y') - 20);
        var halfWidth = ($(this).attr('width') / 2);
        var newX = parseFloat($(this).attr('x')) + halfWidth;
        var output = '<text style="text-anchor: middle;" x="' + newX + '" y="' + newY + '" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#000000" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.875)"><tspan dy="3.75">' + value + '</tspan></text>';
        $("#" + charttype).find("svg").append(ref.parseSVG(output));
      });
    }

  }

  morrisBar2(charttype, morrisChartArr) {
    $('#' + charttype).html('');

    var data = morrisChartArr;
    var $arrColors = ['#1473ad', '#1b97e4', '#6fa3ce', '#636c72', '#b5b5b6',];
    if (data.length > 0) {
      Morris.Bar({
        element: charttype,
        data: data,
        xkey: 'label',
        ykeys: ['value'],
        labels: ['value'],
        //barColors: ['#00cc66'],
        barColors: function (row, series, type) {
          return $arrColors[row.x];
        },
        hideHover: 'always',
        gridLineColor: '#eef0f2',
        xLabelAngle: 45,
        resize: true
      });
      let ref = this;
      var items = $("#" + charttype).find("svg").find("rect");
      $.each(items, function (index, v) {
        var value = data[index].count;
        var newY = ($(this).attr('y') - 20);
        var halfWidth = ($(this).attr('width') / 2);
        var newX = parseFloat($(this).attr('x')) + halfWidth;
        var output = '<text style="text-anchor: middle;" x="' + newX + '" y="' + newY + '" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#000000" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.875)"><tspan dy="3.75">' + value + '</tspan></text>';
        $("#" + charttype).find("svg").append(ref.parseSVG(output));
      });
    }

  }

  loadModule(module: any = '') {
    if (module == 'searchscript' || module == 'resourcescript') {
      if (this.item.roles == 1) {
        this.router.navigate([module + '/all']);
      } else {
        this.toastr.error("Authentication problem", 'error');
      }
    } else {
      this.router.navigate([module + '/all']);
    }
    // switch(module){
    //     case 'organization':
    //         alert(module);
    //         break;

    //     case 'library':
    //         alert(module);
    //         break;

    //     case 'tags':
    //         alert(module);
    //         break;

    //     case 'users':
    //         alert(module);
    //         break;

    //     case 'search_script':
    //         alert(module);
    //         break;

    //     case 'resource_script':
    //         alert(module);
    //         break;

    //     case 'collections':
    //         alert(module);
    //         break;

    //     default:
    //     break;
    // }
  }

  loadMore() {
    this.modalRef.hide();
    this.router.navigate(['survey/all']);
  }

  userList() {
    this.jwt = localStorage.getItem('auth');
    // localStorage.setItem('auth','');
    this._service.userList().subscribe(response => {
      if (response.status == true) {
        this.userCount = response.data.length;
        localStorage.setItem('auth', this.jwt);
      } else {
      }
    })
  }
}
