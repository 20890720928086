import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { Page404Component } from './authentication/page404/page404.component';
import { Recurring_notificationComponent } from './recurring_notification/recurring_notification.component';
import { NotificationComponent } from './notification/notification.component';
import { RecurringNotificationLogsComponent } from './recurring-notification-logs/recurring-notification-logs.component';
import { AnnouncementHistoryComponent } from './announcement-history/announcement-history.component';
import { CannedResourcesComponent } from './canned-resources/canned-resources.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
  }, {
    path: 'recurring_notifications',
    component: Recurring_notificationComponent,
  }, {
    path: 'survey',
    loadChildren: () =>
      import('./history/history.module')
        .then((a) => {
          return a.HistoryModule;
        }),
  },
  {
    path: 'step',
    loadChildren: () => import('./shared/shared.module')
      .then((a) => {
        return a.SharedModule;
      }),
  }, {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  }, {
    path: 'users',
    loadChildren: () => import('./users/users.module')
      .then((a) => {
        return a.UsersModule;
      }),
  }, {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module')
      .then((a) => {
        return a.ChatModule;
      })
  }, {
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module')
      .then((a) => {
        return a.AuthenticationModule;
      }),
  }, {
    path: 'myapp',
    loadChildren: () => import('./myapp/myapp.module')
      .then((a) => {
        return a.MyappModule;
      }),
  },
  {
    path: 'announcement-history',
    component: AnnouncementHistoryComponent,
  },
  /* route added by dev@av start */
  {
    path: 'notifications',
    component: NotificationsComponent,
  }, {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module')
      .then((a) => {
        return a.SettingsModule;
      }),
  }, {
    path: 'resource',
    loadChildren: () => import('./searchscript/searchscript.module')
      .then((a) => {
        return a.SearchscriptModule;
      }),
  }, {
    path: 'resourcescript',
    loadChildren: () => import('./resource-script/resource-script.module')
      .then((a) => {
        return a.ResourceScriptModule;
      }),
  },
  {
    path: 'mainresources',
    loadChildren: () => import('./main-resources/main-resources.module')
      .then((a) => {
        return a.MainResourcesModule;
      }),
  }
  , {
    path: 'push-notification-log',
    component: NotificationComponent,
  }
  , {
    path: 'canned-resource',
    component: CannedResourcesComponent,
  }
  , {
    path: 'recurring-notification-log',
    component: RecurringNotificationLogsComponent,
  },
  {
    path: '**',
    component: Page404Component
  },

  /* route added by dev@av end */
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
