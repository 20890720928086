import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(private firestore: AngularFirestore) { }

  // Collection Real-Time CRUD Operations

  addDataToCollection(collection: string, data: any): Promise<DocumentReference<any>> {
    return this.firestore.collection(collection).add(data);
  }

  getAllDataFromCollection(collection: string): Observable<any[]> {
    return this.firestore.collection(collection, ref => ref.orderBy('created_at')).snapshotChanges()
      .pipe(
        map(actions => actions.map((a: any) => ({ id: a.payload.doc.id, ...a.payload.doc.data() })))
      );
  }

  updateDataInCollection(collection: string, documentId: string, newData: any): Promise<void> {
    return this.firestore.collection(collection).doc(documentId).update(newData);
  }

  // Document Real-Time CRUD Operations

  addDocumentWithCustomId(collection: string, documentId: string, data: any): Promise<void> {
    return this.firestore.collection(collection).doc(documentId).set(data);
  }

  getDocumentById(collection: string, documentId: string): Observable<any> {
    return this.firestore.collection(collection).doc(documentId).snapshotChanges()
      .pipe(
        map((a: any) => ({ id: a.payload.id, ...a.payload.data() }))
      );
  }

  updateDocument(collection: string, documentId: string, newData: any): Promise<void> {
    return this.firestore.collection(collection).doc(documentId).update(newData);
  }

  deleteDocument(collection: string, documentId: string): Promise<void> {
    return this.firestore.collection(collection).doc(documentId).delete();
  }

  addDataByCollectionRef(path: string, newData: any) {
    const documentRef = this.firestore.firestore.doc(path);
    // Set data to the specified document using set() method
    return documentRef.set(newData);
  }

  // Function to get all document IDs under /groups/2_guid/chats
  getAllDocumentIds(path: string) {
    return this.firestore.collection(path).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => a.payload.doc.id))
      );
  }

  deleteCollection(collectionPath: string): Promise<void> {
    const collectionRef = this.firestore.collection(collectionPath);
  
    return new Promise<void>((resolve, reject) => {
      collectionRef.get().toPromise().then(querySnapshot => {
        const batch = this.firestore.firestore.batch();
        querySnapshot.forEach(doc => {
          batch.delete(doc.ref);
        });
  
        // Commit the batch
        batch.commit().then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      }).catch(error => {
        reject(error);
      });
    });
  }
}

