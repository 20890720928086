import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat/chat/chat.component';
import { SharedModule } from '../shared/shared.module';
import { GroupchatComponent } from './chat/chat/components/groupchat/groupchat.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserListWithMessagesComponent } from './components/user-list-with-messages/user-list-with-messages.component';
import { ChatMessageListComponent } from './components/chat-message-list/chat-message-list.component';
import { ChatMessageComposerComponent } from './components/chat-message-composer/chat-message-composer.component';
import { ChatReceiverTextMessageBubbleComponent } from './components/chat-receiver-text-message-bubble/chat-receiver-text-message-bubble.component';
import { ChatSenderTextMessageBubbleComponent } from './components/chat-sender-text-message-bubble/chat-sender-text-message-bubble.component';
import { ChatRoutingModule } from './chat-routing.module';
import { ChatCannedResourceComponent } from './components/chat-canned-resource/chat-canned-resource.component';


@NgModule({
  declarations: [ChatComponent, GroupchatComponent, UserListWithMessagesComponent, UserListComponent, ChatMessageListComponent, ChatMessageComposerComponent, ChatReceiverTextMessageBubbleComponent, ChatSenderTextMessageBubbleComponent, ChatCannedResourceComponent],
  imports: [
    CommonModule,
    ChatRoutingModule,
    SharedModule,
  ]
})
export class ChatModule { }
