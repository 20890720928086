import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { logger } from 'src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/utils/common';
import * as  enums from 'src/utils/enums';
import { CHAT_CONSTANTS } from 'src/utils/messageConstants';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  @Input() userData: any = null;
  @Input() users: any = [];
  @Output() actionGenerated: EventEmitter<any> = new EventEmitter();

  SEARCH: String = 'Search';
  decoratorMessage: String = CHAT_CONSTANTS.NO_USERS_FOUND;


  IntervalUsersList: any = null;
  userChatList: any = null;
  currentChatUser: any = null;
  newChatReq: any = () => { };
  isOnline: boolean = false;

  timeout: any;
  constructor() { }

  ngOnInit(): void {
    try {
    } catch (error) {
      logger(error);
    }
  }

  startChat(user) {
    this.actionGenerated.emit({
      type: enums.START_CHAT,
      payLoad: user
    })
  }

  /**
* Searches for a list of groups matching the search key
* @param Event event
*/
  searchGroup(event: any) {
    try {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      let val = event.target.value;
      this.timeout = setTimeout(() => {
        this.actionGenerated.emit({
          type: enums.SEARCH_USER,
          payLoad: val
        })
      }, 1000);
    } catch (error) {
      logger(error);
    }
  }

  handleIsOnlineChange(event) {
    let val = event.target.checked;
    this.actionGenerated.emit({
      type: enums.IS_ONLINE,
      payLoad: val
    })
  }

}
