import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../dashboard/dashboard.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  settings: any = {};
  historylist: any[];
  organizations: any;
  users: any;
  contentlibrary: any;
  collections: any;
  tags: any;
  search_script: any;
  resource_script: any;
  org_list: any[];
  partner_list: any[];
  userId: any;
  params: any;
  notifications: any = {};
  modalRef: BsModalRef;
  forms = <any>{};

  returnedArray: any[];
  currentPage = 1;
  length: any;
  sortOrder: any = 'ASC';


  constructor(
    private _service: DashboardService,
    private service: SharedService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.params = 1;
    this.pushNotificationLog();
  }



  sortTable(n, sortColumn = '') {

    let table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("myTable");
    switching = true;

    this.sortOrder = this.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    // dir = "asc";
    let tempArr = this.notifications.sort((a, b) => {
      console.log(a);
      console.log(b);
      var data1;
      var data2;
      switch (sortColumn) {

        default:
          if (a) {
            data1 = a[sortColumn].toUpperCase(); // ignore upper and lowercase
          }
          if (b) {
            data2 = b[sortColumn].toUpperCase(); // ignore upper and lowercase
          }
          break;
      }

      if (data1 < data2) {
        return this.sortOrder == 'ASC' ? -1 : 1;
      }
      if (data1 > data2) {
        return this.sortOrder == 'ASC' ? 1 : -1;
      }
      // names must be equal
      return 0;
    })
    this.paginationList(tempArr);
  }

  pushNotificationLog(param: any = '') {
    this.service.pushNotificationLog().subscribe(response => {
      if (response.status == true) {
        this.notifications = response.info;
        this.paginationList(this.notifications);
        this.length = this.notifications.length;
      } else {

      }
    })
  }



  paginationList(tagslist: any) {
    if (this.currentPage != 1) {
      const startItem = (this.currentPage - 1) * 15;
      const endItem = this.currentPage * 15;
      this.returnedArray = this.notifications.slice(startItem, endItem);
    }
    else {
      this.returnedArray = tagslist.slice(0, 15);
    }
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.notifications.slice(startItem, endItem);
    this.currentPage = event.page;
  }



}
