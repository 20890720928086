import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatComponent } from './chat/chat/chat.component';
import { GroupchatComponent } from './chat/chat/components/groupchat/groupchat.component';

const routes: Routes = [
  {
    path: '',
    component: ChatComponent,
    children: [
      { path: "", redirectTo: "group", pathMatch: "full" },
      {
        path: "group",
        component: GroupchatComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatRoutingModule { }