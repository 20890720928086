import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { logger } from 'src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/utils/common';
import { CHAT_CONSTANTS } from 'src/utils/messageConstants';
import * as enums from 'src/utils/enums'
@Component({
  selector: 'app-chat-message-composer',
  templateUrl: './chat-message-composer.component.html',
  styleUrls: ['./chat-message-composer.component.css']
})
export class ChatMessageComposerComponent implements OnInit {

  @Output() actionGenerated: EventEmitter<any> = new EventEmitter();
  @Input() hide: boolean = true;

  ENTER_YOUR_MESSAGE_HERE: string = CHAT_CONSTANTS.ENTER_YOUR_MESSAGE_HERE;
  constructor() { }
  messageInput: any = '';
  enableSendButton = false;
  cannedResourceViewer = false;

  ngOnInit(): void {
  }

  /**
   * Update the Message to be sent on every key press
   * @param event
   */
  changeHandler(event: any) {
    try {
      if (event.target.value.length > 0) {
        this.messageInput = event.target.value;
        this.enableSendButton = true;
      }
      if (event.target.value.length == 0) {
        this.enableSendButton = false;
        this.messageInput = "";
      }
    } catch (error) {
      logger(error);
    }
  }

  /**
   * Send the message if user hits ENTER-key
   * @param Event e
   */
  sendMessageOnEnter(event: any) {
    try {
      if (event.keyCode === 13 && !event.shiftKey) {
        event.preventDefault();
        this.sendTextMessage(event.target.value);
      }
    } catch (error) {
      logger(error);
    }
  }

  /**
   * Send the message if user hits ENTER-key
   * @param Event e
   */
  sendTextMessage(message: any) {
    try {
      if (message) {
        this.actionGenerated.emit({
          type: enums.SEND_SMART_REPLY,
          payLoad: message
        })
        this.messageInput = '';
      }
    } catch (error) {
      logger(error);
    }
  }
  /**
   * Handles all the actions emitted by the child components that make the current component
   * @param Event action
   */
  actionHandler(action: any) {
    try {
      let message = action.payLoad;
      switch (action.type) {
        case enums.SEND_SMART_REPLY: {
          this.sendTextMessage(message);
          break;
        }
        case enums.CLOSE_CANNED_RESOURCE:
          this.toggleCannedResourcePicker();
          break;
        case enums.SEND_CANNED_RESOURCE:
          this.sendTextMessage(message);
          this.toggleCannedResourcePicker();
          break;
      }
    } catch (error) {
      logger(error);
    }
  }


  /**
   * Toggles Canned Resource Window
   */
  toggleCannedResourcePicker(): boolean {
    try {
      const cannedResourceViewer = this.cannedResourceViewer;
      this.cannedResourceViewer = !cannedResourceViewer;
    } catch (error) {
      logger(error);
    }
    return true;
  }

}
