import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-message-list',
  templateUrl: './chat-message-list.component.html',
  styleUrls: ['./chat-message-list.component.css']
})
export class ChatMessageListComponent implements OnInit {
  @Input() messagesList = [];
  @Input() user: any = {};

  constructor() { }

  ngOnInit(): void {
  }
}
