import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { NavbarComponent } from '../navbar/navbar.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';
import { Page404Component } from '../authentication/page404/page404.component';
import { Recurring_notificationComponent } from '../recurring_notification/recurring_notification.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxUploaderModule } from 'ngx-uploader';
import { CKEditorModule } from 'ckeditor4-angular';

@NgModule({
  declarations: [
    NavbarComponent, 
    NotificationsComponent,
     FooterComponent, 
     Page404Component,
     Recurring_notificationComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    PaginationModule,
    BsDatepickerModule,
    TimepickerModule,
    NgxUploaderModule,
    CKEditorModule
  ],
  exports:[
    NavbarComponent,
    FooterComponent
  ]
})
export class SharedModule { }
