import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { logger } from 'src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/utils/common';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-chat-receiver-text-message-bubble',
  templateUrl: './chat-receiver-text-message-bubble.component.html',
  styleUrls: ['./chat-receiver-text-message-bubble.component.css']
})
export class ChatReceiverTextMessageBubbleComponent implements OnInit {
  @Input() message: any = {};
  msgtext: string;
  msgtime: any;
  constructor(private sanitizer: DomSanitizer, private datePipe: DatePipe) { }

  ngOnInit() {
    try {
      this.msgtime = this.formatAMPM(new Date(this.message?.created_at))
      if (this.isHTML(this.message?.message_body?.text)) {
        this.msgtext = `<span>${this.message?.message_body?.text}</span>`
      } else {
        this.msgtext = this.convertTextWithUrlsToHTML(this.message?.message_body?.text);
      }
    } catch (error) {
      logger(error);
    }
  }
  convertTextWithUrlsToHTML(textWithUrls: string): string {
    const regex = /(https?:\/\/[^\s]+)/g;
    return textWithUrls.replace(regex, (match) => {
      const url = match.startsWith('http') ? match : `https://${match}`;
      return `<a href="${url}" target="_blank">${match}</a>`;
    });
  }

  sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  isHTML(input: string): boolean {
    const pattern = /<[a-z][\s\S]*>/i; // Regex pattern to match HTML tags
    return pattern.test(input);
  }

  formatAMPM(date: Date): string {
    return this.datePipe.transform(date, 'h:mm a');
  }
}