import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ChatServiceService {

  private url = Constants.API_URL;
  constructor(private http: HttpClient) { }

  getUser(data) {
    return this.http.post(this.url + "getUser", data).map(res => res['result']);
  }

  getChatUsersList(data) {
    return this.http.post("https://crossroadscareer.net/sandbox/public/get_support_s", data);
  }
  
  setSupportBrowser(data) {
    return this.http.post("https://crossroadscareer.net/sandbox/public/set_support_browser", data);
  }

  createGroupSupport(data) {
    return this.http.post("https://crossroadscareer.net/sandbox/public/group_support", data);
  }
  
  endChat(userId) {
    return this.http.get(`https://crossroadscareer.net/sandbox/public/delete_support_web/${userId}`);
  }
}
