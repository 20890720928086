import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import 'rxjs/add/operator/map';
import { Constants } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private url = Constants.API_URL;
  constructor(private http: HttpClient) {  }

  listAll(params:any){
    return this.http.get(this.url+"dashboard/all/"+params).map(res => res["result"]);
  }

  statsData(){
    return this.http.get(this.url+"stats").map(res => res['result']);
  }

  userList(){   
    return this.http.get(this.url+"userlist").map(res => res["result"]);
    // return this.http.get(this.url+"userlist").map(res => JSON.parse(res['_body'])["result"]);
  }
  
  systemWidehistory(){
    return this.http.get(this.url+"history/systemWide").map(res => res["result"]);
  }

  showList(){
    return this.http.get(this.url+"userlist").map(res => res["result"]);
  }

  showPrayers(){
    return this.http.get(this.url+"showprayers").map(res => res["result"]);
  }

  showSurvey(delta){
    return this.http.get(this.url+"showsurvey/"+delta).map(res => res["result"]);
  }

}
